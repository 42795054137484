.app {
  display: flex;
  min-height: 100vh;
}

.bg {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #4f1470; */
  background: linear-gradient(#8523b1, #3a005a);
  /* background-image: url('./bg-purple.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px;
}

.card {
  background: white;
  border-radius: 16px;
  max-width: 520px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0,0,0,.3);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: url('./danno.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 8px solid white;
  margin: 0 0 30px;
}

.bio {
  text-align: center;
  max-width: 460px;
  width: 100%;
}

.btn {
  display: inline-block;
  height: 60px;
  border-radius: 30px;
  background: #80F;
  line-height: 60px;
  color: white;
  text-decoration: none;
  padding: 0 45px;
  font-weight: 600;
  margin: 0 0 30px;
  transition: all .15s ease-out;
}

.btn:hover {
  transform: scale(1.05);
  box-shadow: 0 3px 7px #00000021;
  background: #5f06ae;

}

.social {
  display: inline-block;
  list-style: none;
  margin: 0 10px;
}

.icon {
  transition: all .15s ease-out;
}

.icon:hover {
  fill: #80F;
}

@media all and (max-width: 420px) {
  h1 {
    font-size: 2em;
  }
}
